
import { mapState } from 'vuex'
export default {
    name: 'CustomDialog',
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState({
            showDialog: (state) => state.dialog.showDialog,
            dialogTitle: (state) => state.dialog.dialogTitle,
            dialogSubtitle: (state) => state.dialog.dialogSubtitle,
            type: (state) => state.dialog.type,
            colorStore: (state) => state.dialog.color,
            confirmText: (state) => state.dialog.confirmText,
            confirmButtonFunc: (state) => state.dialog.confirmButtonFunc,
            customIcon: (state) => state.dialog.icon,
            description: (state) => state.dialog.description,
        }),
        dialog: {
            set(value) {
                if (value === false) {
                    this.$store.commit('dialog/setConfirmButtonStatus', null)
                    this.$store.dispatch('dialog/clearDialogTimeout', null)
                }
                this.$store.dispatch('dialog/setShowDialog', { display: value })
            },
            get() {
                return this.showDialog
            },
        },
        isSuccess() {
            return this.type === 'success'
        },
        isConfirm() {
            return this.type === 'confirm'
        },
        isError() {
            return this.type === 'error'
        },
        color() {
            if (this.isSuccess) return this.colorStore || 'success'
            if (this.isError) return this.colorStore || 'error'
            if (this.isConfirm) return this.colorStore || 'primary'
            return 'primary'
        },
        toastComponentName() {
            if (this.isConfirm) return 'ToastOnConfirm'
            if (this.isError) return 'ToastOnError'
            if (this.isSuccess) return 'ToastOnSuccess'
            else return null
        },
    },
    methods: {
        onClickConfirm() {
            this.$store.commit('dialog/setConfirmButtonStatus', true)
            setTimeout(() => {
                this.dialog = false
            }, 50)

            // If callback function is set
            if (this.confirmButtonFunc) {
                this.confirmButtonFunc()
            }
        },
        onClickCancel() {
            this.$store.commit('dialog/setConfirmButtonStatus', false)
            setTimeout(() => {
                this.dialog = false
            }, 50)
        },
    },
}
