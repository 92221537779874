
import versionHistory from '~/static/version-history.json'
export default {
    props: {
        displayName: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        versionHistory,
    }),
    methods: {
        usernameChar() {
            if (this.$cookie.get('user') && this.$cookie.get('user').name) {
                return this.$cookie.get('user').name.toString().charAt(0) || 'C'
            }
        },
        async logout() {
            const isConfirm = await this.$toaster({
                title: this.tl('areYouSureToLogout'),
                type: 'confirm',
            })
            if (isConfirm) {
                this.$store.dispatch('auth/logout')
            }
        },
    },
}
